<template>
    <div class="home wraper">
        <el-backtop target="">
            <div class="homepage-backtop">
                <i class="el-icon-caret-top"></i>
            </div>
        </el-backtop>
        <header class="home-nav">
            <div class="home-header-box df">
                <a href="#">
                    <div class="home-logo df">
                        <img src="@/assets/img/etsc_logo.png" alt="">
                        <span>ETSC</span>
                    </div>
                </a>
                <div class="home-header-rt-box df">
                    <div class="df home-header">
                        <div>
                            <a href="#dapp">
                                <span>{{ $t('m.home.nav.0') }}</span>
                            </a>
                        </div>
                        <div>
                            <a href="#browser">
                                <span>{{ $t('m.home.nav.1') }}</span>
                            </a>
                        </div>
                        <div>
                            <a href="#wallet">
                                <span>{{ $t('m.home.nav.2') }}</span>
                            </a>
                        </div>
                        <div>
                            <span>{{ $t('m.home.nav.3') }}</span>
                        </div>
                        <div>
                            <a href="#about">
                                <span>{{ $t('m.home.nav.4') }}</span>
                            </a>
                        </div>
                        <div>
                            <a class="download"  v-if="language == 'zh-CN'" href="http://wallet.etsc.online/app/ETSC白皮书2.0.pdf" target="_blank">
                                <span>{{ $t('m.home.nav.5') }}</span>
                            </a>
                            <a class="download"  v-else href="http://wallet.etsc.online/app/ETSC-White-Paper-2.0.pdf" target="_blank">
                                <span>{{ $t('m.home.nav.5') }}</span>
                            </a>
                        </div>
                    </div>
                    <div class="home-header-language">
                        <span>{{ language == 'zh-CN' ? '简体中文' : 'English' }}</span>
                        <ul class="language-list">
                            <li @click="doLanguage('zh-CN')">简体中文</li>
                            <li @click="doLanguage('en-US')">English</li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
        <div class="home-swipe">
            <div class="home-video-box df">
                <div class="home-swipe-video df" @click="showVideo(0)">
                    <i class="icon-video"></i>
                </div>
                <div class="home-swipe-video df" @click="showVideo(1)">
                    <i class="icon-video"></i>
                </div>
            </div>
        </div>
        <section class="section-1">
            <div class="section-title section-title-1" v-if="language == 'zh-CN'"></div>
            <div class="section-title section-title-1-en" v-else></div>
            <p class="section-title-text">{{ $t('m.home.text.0') }}</p>
            <div class="section-list df">
                <div class="section-item section-fff">
                    <div class="section-img df">
                        <img src="@/assets/img/tuntu.png" alt="" class="icon-tuntu">
                    </div>
                    <h2>{{ $t('m.home.introduction.item.0') }}</h2>
                    <p>{{ $t('m.home.introduction.word.0') }}</p>
                </div>
                <div class="section-item section-fff">
                    <div class="section-img df">
                        <img src="@/assets/img/kuozhan.png" alt="" class="icon-kuozhan">
                    </div>
                    <h2>{{ $t('m.home.introduction.item.1') }}</h2>
                    <p>{{ $t('m.home.introduction.word.1') }}</p>
                </div>
                <div class="section-item section-fff">
                    <div class="section-img df">
                        <img src="@/assets/img/kekao.png" alt="" class="icon-kekao">
                    </div>
                    <h2>{{ $t('m.home.introduction.item.2') }}</h2>
                    <p>{{ $t('m.home.introduction.word.2') }}</p>
                </div>
                <div class="section-item section-fff">
                    <div class="section-img df">
                        <img src="@/assets/img/anquan.png" alt="" class="icon-anquan">
                    </div>
                    <h2>{{ $t('m.home.introduction.item.3') }}</h2>
                    <p>{{ $t('m.home.introduction.word.3') }}</p>
                </div>
            </div>
        </section>
        <section class="section-2">
            <div class="section-title section-title-2" v-if="language == 'zh-CN'"></div>
            <div class="section-title section-title-2-en" v-else></div>
            <p class="section-title-text">{{ $t('m.home.text.1') }}</p>
            <div class="section-box">
                <div class="section-cell df">
                    <div class="section-lf">
                        <h2>{{ $t('m.home.advantage.item') }}</h2>
                        <p>{{ $t('m.home.advantage.word') }}</p>
                    </div>
                    <div class="section-rt">
                        <div class="section-line etsc-line">
                            <div class="line-title">ETSC</div>
                            <div class="df line-box">
                                <span>10500 TPS</span>
                                <span class="line"></span>
                            </div>
                            <div class="line-time">5 Secs</div>
                        </div>
                        <div class="section-line eth-line">
                            <div class="line-title">ETH</div>
                            <div class="df line-box">
                                <span>100 TPS</span>
                                <span class="line"></span>
                            </div>
                            <div class="line-time">15 Secs</div>
                        </div>
                        <div class="section-line btc-line">
                            <div class="line-title">BTC</div>
                            <div class="df line-box">
                                <span>3-6 TPS</span>
                                <span class="line"></span>
                            </div>
                            <div class="line-time">600 Secs</div>
                        </div>
                    </div>
                </div>
            </div>
            <!--dapp锚链接-->
            <a href="javascript:void(0)" name="dapp"></a>
        </section>
        <!--dapp-->
        <section class="section-3">
            <div class="section-title section-title-3"></div>
            <p class="section-title-text">{{ $t('m.home.text.2') }}</p>
            <div class="section-dapp-box df">
                <div class="section-dapp-item">
                    <div class="dapp-img">
                        <img src="@/assets/img/tid.png">
                    </div>
                    <div class="dapp-content">
                        <div class="df dapp-title">
                            <span class="name">TID</span>
                            <a class="download" href="http://wallet.etsc.online/app/TID.pdf" target="_blank">{{ $t('m.home.dapp.word.0') }}</a>
                        </div>
                        <div class="dapp-tips">
                            <p>{{ $t('m.home.dapp.item.0') }}</p>
                        </div>
                    </div>
                </div>
                <div class="section-dapp-item">
                    <div class="dapp-img">
                        <img src="@/assets/img/tyche.png">
                    </div>
                    <div class="dapp-content">
                        <div class="df dapp-title">
                            <span class="name">Tyche</span>
                            <a class="download" href="http://wallet.etsc.online/app/TYCHE.pdf" target="_blank">{{ $t('m.home.dapp.word.0') }}</a>
                        </div>
                        <div class="dapp-tips">
                            <p>{{ $t('m.home.dapp.item.1') }}</p>
                        </div>
                    </div>
                </div>
                <div class="section-dapp-item">
                    <div class="dapp-img">
                        <img src="@/assets/img/moon.png">
                    </div>
                    <div class="dapp-content">
                        <div class="df dapp-title">
                            <span class="name">Moon</span>
                        </div>
                        <div class="dapp-tips">
                            <p>{{ $t('m.home.dapp.item.2') }}</p>
                        </div>
                    </div>
                </div>
                <div class="section-dapp-item">
                    <div class="dapp-img">
                        <img src="@/assets/img/pps.png">
                    </div>
                    <div class="dapp-content">
                        <div class="df dapp-title">
                            <span class="name">PPS</span>
<!--                            <a class="download" href="http://wallet.etsc.online/app/PPS-B.pdf" target="_blank">{{ $t('m.home.dapp.word.0') }}</a>-->
                        </div>
                        <div class="dapp-tips">
                            <p>{{ $t('m.home.dapp.item.9') }}</p>
                        </div>
                    </div>
                </div>
                <div class="section-dapp-item">
                  <div class="dapp-img">
                    <img src="@/assets/img/cmc.png">
                  </div>
                  <div class="dapp-content">
                    <div class="df dapp-title">
                      <span class="name">CMC</span>
<!--                      <a class="download" href="http://wallet.etsc.online/app/CMC.pdf" target="_blank">{{ $t('m.home.dapp.word.0') }}</a>-->
                    </div>
                    <div class="dapp-tips">
                      <p>{{ $t('m.home.dapp.item.10') }}</p>
                    </div>
                  </div>
                </div>
                <div class="section-dapp-item">
                    <div class="dapp-img">
                        <img src="@/assets/img/swe.png">
                    </div>
                    <div class="dapp-content">
                        <div class="df dapp-title">
                            <span class="name">SWE</span>
                        </div>
                        <div class="dapp-tips">
                            <p>{{ $t('m.home.dapp.item.4') }}</p>
                        </div>
                    </div>
                </div>
                <div class="section-dapp-item">
                    <div class="dapp-img">
                        <img src="@/assets/img/hbh.png">
                    </div>
                    <div class="dapp-content">
                        <div class="df dapp-title">
                            <span class="name">HBH</span>
                            <a class="download" href="http://wallet.etsc.online/app/EPSM.pdf" target="_blank">{{ $t('m.home.dapp.word.0') }}</a>
                        </div>
                        <div class="dapp-tips">
                            <p>{{ $t('m.home.dapp.item.5') }}</p>
                        </div>
                    </div>
                </div>
                <div class="section-dapp-item">
                    <div class="dapp-img">
                        <img src="@/assets/img/mapping.png">
                    </div>
                    <div class="dapp-content">
                        <div class="df dapp-title">
                            <span class="name">ETSC Pledge/Mapping</span>
                        </div>
                        <div class="dapp-tips">
                            <p>{{ $t('m.home.dapp.item.6') }}</p>
                        </div>
                    </div>
                </div>
                <div class="section-dapp-item">
                    <div class="dapp-img">
                        <img src="@/assets/img/community.png">
                    </div>
                    <div class="dapp-content">
                        <div class="df dapp-title">
                            <span class="name">{{ $t('m.home.dapp.word.1') }}</span>
                        </div>
                        <div class="dapp-tips">
                            <p>{{ $t('m.home.dapp.item.7') }}</p>
                        </div>
                    </div>
                </div>
              <div class="section-dapp-item">
                <div class="dapp-img">
                  <img src="@/assets/img/fct.jpg">
                </div>
                <div class="dapp-content">
                  <div class="df dapp-title">
                    <span class="name">FCT</span>
                    <a class="download" href="http://wallet.etsc.online/app/FCT.pdf" target="_blank">{{ $t('m.home.dapp.word.0') }}</a>
                  </div>
                  <div class="dapp-tips">
                    <p>{{ $t('m.home.dapp.item.11') }}</p>
                  </div>
                </div>
              </div>
              <div class="section-dapp-item">
                <div class="dapp-img">
                  <img src="@/assets/img/nbc.png">
                </div>
                <div class="dapp-content">
                  <div class="df dapp-title">
                    <span class="name">NBC</span>
                    <a class="download" href="http://wallet.etsc.online/app/NBC.pdf" target="_blank">{{ $t('m.home.dapp.word.0') }}</a>
                  </div>
                  <div class="dapp-tips">
                    <p>{{ $t('m.home.dapp.item.12') }}</p>
                  </div>
                </div>
              </div>
                <div class="section-dapp-item">
                    <div class="dapp-more">
                        <div class="dapp-mask df">
                            <span></span>
                        </div>
                        <div class="dapp-more-text df">
                            <span>{{ $t('m.home.dapp.word.2') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!--wallet锚链接-->
            <a href="javascript:void(0)" name="wallet"></a>
        </section>
        <!--wallet-->
        <section class="section-4">
            <div class="section-title section-title-4" v-if="language == 'zh-CN'"></div>
            <div class="section-title section-title-4-en" v-else></div>
            <p class="section-title-text">{{ $t('m.home.text.3') }}</p>
            <div class="section-4-box df">
                <!--  <div class="section-download-ios">
                      <span>APP Store</span>
                  </div>-->
                <a class="section-download-android" href="http://wallet.etsc.online/app/ETSC-Wallet_2_3_5.apk" target="_blank">
                    <span>Android</span>
                </a>
            </div>
            <!--browser-->
            <a href="javascript:void(0)" name="browser"></a>
        </section>
        <section class="section-5">
            <div class="section-5-top">
                <div class="section-5-title">
                    <h2>{{ $t('m.home.title.3') }}</h2>
                    <p>{{ $t('m.home.text.4') }}</p>
                </div>
                <a href="https://www.etscscan.io/" target="_blank">
                    <div class="section-5-btn">
                        <span>{{ $t('m.home.button.0') }}</span>
                    </div>
                </a>
            </div>
            <div class="section-5-box">
                <div class="section-title section-title-5" v-if="language == 'zh-CN'"></div>
                <div class="section-title section-title-5-en" v-else></div>
                <p class="section-title-text">{{ $t('m.home.text.5') }}</p>
                <div class="section-5-form">
                    <div class="form-cell">
                        <p>{{ $t('m.home.submit.0') }}</p>
                        <div class="form-input">
                            <input type="text" v-model="form.name">
                        </div>
                    </div>
                    <div class="form-cell">
                        <p>{{ $t('m.home.submit.1') }}</p>
                        <div class="form-input">
                            <input type="number" v-model="form.mobile">
                        </div>
                    </div>
                    <div class="form-cell">
                        <p>{{ $t('m.home.submit.2') }}</p>
                        <div class="form-input">
                            <input type="text" v-model="form.number">
                        </div>
                    </div>
                    <div class="form-cell">
                        <p>{{ $t('m.home.submit.3') }}</p>
                        <div class="form-input">
                            <input type="text" v-model="form.addr">
                        </div>
                    </div>
                    <div class="form-cell">
                        <p>{{ $t('m.home.submit.4') }}</p>
                        <div class="form-input">
                            <input type="text" v-model="form.email">
                        </div>
                    </div>
                    <div class="form-cell">
                        <p>{{ $t('m.home.submit.5') }}</p>
                        <div class="form-input">
                            <textarea rows="5" v-model="form.msg"></textarea>
                        </div>
                    </div>
                    <div class="form-btn" @click="commit">
                        <span>{{ $t('m.home.button.1') }}</span>
                    </div>
                </div>
            </div>
            <!--about-->
            <a href="javascript:void(0)" name="about"></a>
        </section>

        <section class="section-6">
            <div class="section-title section-title-6" v-if="language == 'zh-CN'"></div>
            <div class="section-title section-title-6-en" v-else></div>
            <p class="section-title-text">{{ $t('m.home.text.6') }}</p>
            <div class="section-6-box df" :class="language == 'zh-CN' ? '' : 'section-6-box-en'">
                <div class="section-6-item mt200">
                    <div class="section-6-circle df">
                        <div>
                            <span>02</span>
                            <span>2018</span>
                        </div>
                    </div>
                    <div class="section-6-text">
                        {{ $t('m.home.course.0') }}
                    </div>
                </div>
                <div class="section-6-item">
                    <div class="section-6-circle df">
                        <div>
                            <span>04</span>
                            <span>2018</span>
                        </div>
                    </div>
                    <div class="section-6-text">
                        {{ $t('m.home.course.1') }}
                    </div>
                </div>
                <div class="section-6-item mt200 active">
                    <div class="section-6-circle df">
                        <div>
                            <span>09</span>
                            <span>2018</span>
                        </div>
                    </div>
                    <div class="section-6-text" style="width: 75px;">
                        {{ $t('m.home.course.2') }}
                    </div>
                </div>
                <div class="section-6-item mt-400">
                    <div class="section-6-text">
                        {{ $t('m.home.course.4') }}
                    </div>
                    <div class="section-6-circle df before">
                        <div>
                            <span>07</span>
                            <span>2019</span>
                        </div>
                    </div>
                </div>
                <div class="section-6-item mt200">
                    <div class="section-6-circle df">
                        <div>
                            <span>09</span>
                            <span>2019</span>
                        </div>
                    </div>
                    <div class="section-6-text">
                        {{ $t('m.home.course.5') }}
                    </div>
                </div>
                <div class="section-6-item mt-200">
                    <div class="section-6-circle df">
                        <div>
                            <span>11</span>
                            <span>2019</span>
                        </div>
                    </div>
                    <div class="section-6-text" style="width: 100px">
                        {{ $t('m.home.course.3') }}
                    </div>
                </div>
                <div class="section-6-item mt-300">
                    <div class="section-6-circle df">
                        <div>
                            <span>12</span>
                            <span>2019</span>
                        </div>
                    </div>
                    <div class="section-6-text">
                        {{ $t('m.home.course.6') }}
                    </div>
                </div>
                <div class="section-6-item">
                    <div class="section-6-circle df">
                        <div>
                            <span>02</span>
                            <span>2020</span>
                        </div>
                    </div>
                    <div class="section-6-text" style="width: 100px">
                        {{ $t('m.home.course.7') }}
                    </div>
                </div>
                <div class="section-6-item mt200">
                    <div class="section-6-circle df">
                        <div>
                            <span>03</span>
                            <span>2020</span>
                        </div>
                    </div>
                    <div class="section-6-text" style="width: 90px;">
                        {{ $t('m.home.course.8') }}
                    </div>
                </div>
                <div class="section-6-item mt-200">
                    <div class="section-6-circle df">
                        <div>
                            <span>05</span>
                            <span>2020</span>
                        </div>
                    </div>
                    <div class="section-6-text">
                        {{ $t('m.home.course.9') }}
                    </div>
                </div>
                <div class="section-6-item mt-300">
                    <div class="section-6-circle df">
                        <div>
                            <span>06</span>
                            <span>2020</span>
                        </div>
                    </div>
                    <div class="section-6-text" style="width: 100px">
                        {{ $t('m.home.course.10') }}
                    </div>
                </div>
                <div class="section-6-item">
                    <div class="section-6-circle df">
                        <div>
                            <span>07</span>
                            <span>2020</span>
                        </div>
                    </div>
                    <div class="section-6-text">
                        {{ $t('m.home.course.11') }}
                    </div>
                </div>
                <div class="section-6-item mt200">
                    <div class="section-6-circle df">
                        <div>
                            <span>10</span>
                            <span>2020</span>
                        </div>
                    </div>
                    <div class="section-6-text">
                        {{ $t('m.home.course.12') }}
                    </div>
                </div>
                <div class="section-6-item">
                    <div class="section-6-circle df">
                        <div>
                            <span>11</span>
                            <span>2020</span>
                        </div>
                    </div>
                    <div class="section-6-text" style="width: 90px">
                        {{ $t('m.home.course.13') }}
                    </div>
                </div>
              <div class="section-6-item mt-200">
                <div class="section-6-circle df">
                  <div>
                    <span>04</span>
                    <span>2021</span>
                  </div>
                </div>
                <div class="section-6-text">
                  {{ $t('m.home.course.14') }}
                </div>
              </div>
              <div class="section-6-item mt-300">
                <div class="section-6-circle df">
                  <div>
                    <span>02</span>
                    <span>2022</span>
                  </div>
                </div>
                <div class="section-6-text" style="width: 100px">
                  {{ $t('m.home.course.15') }}
                </div>
              </div>
              <div class="section-6-item">
                <div class="section-6-circle df">
                  <div>
                    <span>09</span>
                    <span>2022</span>
                  </div>
                </div>
                <div class="section-6-text" style="width: 100px">
                  {{ $t('m.home.course.16') }}
                </div>
              </div>
              <div class="section-6-item mt200">
                <div class="section-6-circle df">
                  <div>
                    <span>03</span>
                    <span>2023</span>
                  </div>
                </div>
                <div class="section-6-text" style="width: 90px;">
                  {{ $t('m.home.course.17') }}
                </div>
              </div>
              <div class="section-6-item">
                <div class="section-6-circle df">
                  <div>
                    <span>3</span>
                    <span>2024</span>
                  </div>
                </div>
                <div class="section-6-text" style="width: 90px">
                  {{ $t('m.home.course.18') }}
                </div>
              </div>
            </div>
        </section>
        <footer class="home-footer">
            <div class="footer-box">
                <div class="footer-number">
                    <p>{{ $t('m.home.footer.0') }}</p>
                    <p>ETSCETSC2.0@gmail.com</p>
                </div>
                <div class="df sponsor">
                    <div>
                        <img src="@/assets/img/twiter.png" alt="">
                    </div>
                    <div>
                        <img src="@/assets/img/facebook.png" alt="">
                    </div>
                    <div>
                        <img src="@/assets/img/github.png" alt="">
                    </div>
                    <div>
                        <img src="@/assets/img/youtube.png" alt="">
                    </div>
                    <div>
                        <img src="@/assets/img/email.png" alt="">
                    </div>
                </div>
            </div>
            <div class="footer-address">
                <span>{{ $t('m.home.footer.1') }}</span>
            </div>
        </footer>
        <div class="section-video df" v-show="isVideo">
            <div class="section-video-close" @click="closeVideo()"></div>
            <video src="@/assets/video/home1.mp4" controls="controls" v-if="videoKey==0"></video>
            <video src="@/assets/video/home2.mp4" controls="controls" v-else></video>
        </div>
        <div class="video-mask" v-show="isVideo" @click="closeVideo()"></div>

    </div>
</template>

<script>
    import '@/assets/css/main.scss'
    import axios from 'axios'
    import {Notification} from 'element-ui';

    export default {
        name: 'Home',
        data() {
            return {
                isVideo: false,
                videoKey: 0,
                form: {
                    name: '',
                    mobile: '',
                    number: '',
                    addr: '',
                    email: '',
                    msg: '',
                    language: 'en-US'
                }
            }
        },
        created() {
            this.language = localStorage.getItem("lang") ? localStorage.getItem("lang") : 'en-US';
        },
        methods: {
            showVideo(index) {
                this.isVideo = true;
                this.videoKey = index;
            },
            closeVideo() {
                this.isVideo = false;
            },
            commit() {
                if (this.form.name == '') return Notification.error({title: this.$t('m.home.message.8'), message: this.$t('m.home.message.0')});
                if (this.form.mobile == '') return Notification.error({title: this.$t('m.home.message.8'), message: this.$t('m.home.message.1')});
                if (this.form.number == '') return Notification.error({title: this.$t('m.home.message.8'), message: this.$t('m.home.message.2')});
                if (this.form.addr == '') return Notification.error({title: this.$t('m.home.message.8'), message: this.$t('m.home.message.3')});
                if (this.form.email == '') return Notification.error({title: this.$t('m.home.message.8'), message: this.$t('m.home.message.4')});
                if (this.form.msg == '') return Notification.error({title: this.$t('m.home.message.8'), message: this.$t('m.home.message.5')});
                let url = 'http://communityfund.etsc.online/index/message/form';
                let data = {
                    username: this.form.name,
                    mobile: this.form.mobile,
                    miner: this.form.number,
                    address: this.form.addr,
                    email: this.form.email,
                    content: this.form.msg
                };
                axios.post(url, data).then(res => {
                    this.form = {};
                    this.$notify({
                        title: this.$t('m.home.message.6'),
                        message: this.$t('m.home.message.7'),
                        type: 'success'
                    });
                })
            },
            doLanguage(language) {
                this.language = language;
                localStorage.setItem("lang", language);
                this.$i18n.locale = language;
            }
        }
    }
</script>
<style lang="scss"></style>
